import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import parse from "html-react-parser"

const PageTemplate = ({ data }) => {
  const page = data.page
  {
    return (
      <Layout>
        {page.featuredImage}
        <div className="container" id="page-container">
          <div className="title-container">
            <h1 className="page-title">{page.title}</h1>
            <hr></hr>
          </div>

          <div> {parse(page.content)}</div>
        </div>
      </Layout>
    )
  }
}
export const query = graphql`
  query GET_PAGE($id: String!) {
    page: wpPage(id: { eq: $id }) {
      title
      content
      databaseId
      uri
      slug
      featuredImage {
        node {
          mediaItemUrl
        }
      }
    }
  }
`

export default PageTemplate
